.blog-post-content, .entry-content {
    padding-left: 1em;
    padding-right: 1em;
    text-align: left;
    display: table-cell;
    box-sizing: border-box;
    max-width: auto;
    overflow: auto;
}

.blog-post-content p {
    margin-block-end: 1em;
}

.entry-content h1, .blog-post-content h1, .blog-post-content h2, .blog-post-content h3, .blog-post-content h4 {
    font-weight: bold;
}

.blog-post-content img {
    max-width: 100%;
    margin: 1em auto 1em;
    height: auto;
    width: auto;
    align-content: center;
}

.blog-post-content table {
    margin: 1em auto 1em;
    max-width: 100%;
    display: inline-block;
    word-break: break-all;
}

*.li, .blog-post-content li, .blog-post-content ol {
    padding-left: 15px;
}

.pre {
    overflow-x: auto;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-all;
}

.MuiTableCell-root {
    padding: 10px !important;
    width: min-content !important;
    height: min-content !important;
    max-width: 800px !important;
}

.MuiTablePagination-caption {
    font-size: 18px !important;
    color: gray !important;
}

.MuiTableHead-root {
    height: 0x;
}
